<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 개정 -->
          <c-card title="LBLREV" class="cardClassDetailForm revisionLayout" topClass="topcolor-orange" v-if="popupParam.psiFireproofStructureId">
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="row">
                  <div class="col-1">
                    <!-- 개정번호 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVNO"
                      name="revisionNum"
                      v-model="data.revisionNum">
                    </c-text>
                  </div>
                  <div class="col-2">
                    <!-- 개정일시 -->
                    <c-text
                      :editable="false"
                      label="LBLREVPERIOD"
                      name="regDtStr"
                      v-model="data.regDtStr">
                    </c-text>
                  </div>
                  <div class="col-1">
                    <!-- 개정자 -->
                    <c-text
                      :editable="false"
                      label="LBLREVUSER"
                      name="regUserName"
                      v-model="data.regUserName">
                    </c-text>
                  </div>
                  <div class="col-4">
                    <!-- 개정사유 -->
                    <c-text
                      :required="editable && isRevision"
                      :editable="editable && isRevision"
                      label="LBLREVREASON"
                      name="revisionContent"
                      v-model="data.revisionContent">
                    </c-text>
                  </div>
                </div>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 내화구조 명세 상세정보 -->
          <c-card title="LBL0010495" class="cardClassDetailForm">
            <template slot="card-select">
              <!-- 개정이력 -->
              <c-select
                :editable="!isRevision"
                v-if="popupParam.psiFireproofStructureId"
                :comboItems="gridrev.data"
                type="custom"
                typetext="LBL0010565"
                itemText="revisionNum"
                itemValue="psiFireproofStructureId"
                name="selectedpsiFireproofStructureId"
                label=""
                v-model="selectedpsiFireproofStructureId"
                @input="rowClickRev"
              ></c-select>
            </template>
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustomTop">
                      <!-- (주) -->
                      {{$label('LBL0010385')}}
                    </div>
                    <div class="tooltipCustom">
                      <!-- ① 내화설비 또는 지역은 건축물명, 배관지지대명, 설비명 등을 기재 -->
                      {{$message('MSG0010043')}}
                      <br>
                      <!-- ② 내화부위는 내화의 범위(예: 배관지지대 등)를 기재 -->
                      {{$message('MSG0010044')}}
                      <br>
                     <!-- ③ 내화시험기준 및 시간은 한국산업규격에 따른 내화시험방법에 의하여 기재 -->
                     {{$message('MSG0010045')}}
                    </div>
                  </q-tooltip>
                </q-btn>
                <!-- 개정 -->
                <c-btn
                  v-show="editable && popupParam.psiFireproofStructureId && !isRevision"
                  label="LBLREV"
                  icon="restart_alt"
                  @btnClicked="SetRevision" />
                <!-- 개정취소 -->
                <c-btn
                  v-show="editable && popupParam.psiFireproofStructureId && isRevision"
                  label="LBLREVCANCEL"
                  icon="restart_alt"
                  @btnClicked="CancelRevision" />
                <!-- 삭제 -->
                <c-btn
                  v-if="editable && popupParam.psiFireproofStructureId"
                  label="LBLREMOVE"
                  icon="remove"
                  @btnClicked="deleteData" />
                <!-- 저장 -->
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <!-- 내화구조설비 및 지역명 -->
                <c-text
                  :required="true"
                  :editable="editable"
                  label="LBL0010496"
                  name="fireproofStructureName"
                  v-model="data.fireproofStructureName">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-6">
                <!-- 내화부위 -->
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="LBL0010492"
                  name="fireproofProofPart"
                  v-model="data.fireproofProofPart">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 시험기준및시간 -->
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="LBL0010493"
                  name="testStndardTime"
                  v-model="data.testStndardTime">
                </c-textarea>
              </div>
              <div class="col-12">
                <!-- 비고 -->
                <c-textarea
                  :editable="editable"
                  :rows="3"
                  label="LBLREMARK"
                  name="remark"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-6">
                <!-- 관련공정 -->
                <c-process
                  :editable="editable"
                  label="LBL0001705"
                  multiple="single"
                  name="processCd"
                  v-model="data.processCd">
                </c-process>
              </div>
              <div class="col-6">
                <!-- 관리부서 -->
                <c-dept
                  :editable="editable"
                  label="LBL0001721"
                  name="deptCd"
                  v-model="data.deptCd">
                </c-dept>
              </div>
            </template>
          </c-card>
        </div>
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';

export default {
  name: 'fire-proof-detail',

  props: {
    popupParam: {
      type: Object,
      default: () => ({
        psiFireproofStructureId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      saveCallData: '',
      selectedpsiFireproofStructureId: null,
      saveUrl: transactionConfig.psi.lbe.fireproof.insert.url,
      mappingType: 'POST',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      data: {
        psiFireproofStructureId: '',  // 내화구조명세 일련번호
        plantCd: '',  // 사업장코드
        fireproofStructureName: '',  // 내화구조설비및지역명
        fireproofProofPart: '',  // 내화부위
        testStndardTime: '',  // 시험기준및시간
        remark: '',  // 비고
        processCd: '',
        deptCd: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        regDtStr: '',
        regUserName: '',
        sopMocId: ''
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      gridrev: {
        columns: [
          {
            name: 'revisionNum',
            field: 'revisionNum',
            // 개정번호
            label: 'LBLREVNO',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
          {
            name: 'revisionContent',
            field: 'revisionContent',
            // 개정사유
            label: 'LBLREVREASON',
            align: 'left',
            sortable: true,
          },
          {
            name: 'regDtStr',
            field: 'regDtStr',
            // 개정일시
            label: 'LBLREVPERIOD',
            style: 'width: 150px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'regUserName',
            field: 'regUserName',
            // 개정자
            label: 'LBLREVUSER',
            align: 'center',
            style: 'width: 80px',
            sortable: true,
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.psi.lbe.fireproof.get.url;
      this.insertUrl = transactionConfig.psi.lbe.fireproof.insert.url;
      this.updateUrl = transactionConfig.psi.lbe.fireproof.update.url;
      this.deleteUrl = transactionConfig.psi.lbe.fireproof.delete.url;
      this.revlistUrl = selectConfig.psi.lbe.fireproof.list.url + '/revs';
      this.getData();
    },
    getData() {
      this.isRevision = false;
      if (this.popupParam.psiFireproofStructureId) {
        this.getDetail(this.popupParam.psiFireproofStructureId, true);
        this.selectedpsiFireproofStructureId = this.popupParam.psiFireproofStructureId
      }
    },
    getDetail(_psiFireproofStructureId, _check) {
      this.$http.url = this.$format(this.detailUrl, _psiFireproofStructureId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        // 상세팝업 최초작성/최근수정 정보 노출
        this.$emit('setRegInfo', _result.data)
        this.isRevision = false;
        if (_check) {
          this.getRevList(_result.data.groupId);
        }
        if(_result.data.psiFireproofStructureId == this.popupParam.psiFireproofStructureId) {
          this.editable = this.$route.meta.editable;
        } else {
          this.editable = false;
        }
      },);
    },
    saveData() {
      if (this.popupParam.psiFireproofStructureId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';  // 저장하시겠습니까?
      if (this.isRevision) {
        saveMsg = 'MSGREVSAVE';
        // 현재버전을 개정하여 저장하시겠습니까?
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',  // 확인
            message: saveMsg,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId;
              this.data.chgUserId = this.$store.getters.user.userId;
              // 개정시
              if (this.isRevision) {
                this.saveUrl = this.insertUrl;
                this.mappingType = 'POST';
                this.data.sysRevision = parseInt(this.data.sysRevision) + 1;
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteData() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',  // 확인
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.psiFireproofStructureId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isdelete = !this.isdelete;
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.psiFireproofStructureId = result.data.psiFireproofStructureId
      this.saveCallData = uid();
      this.getData();
    },
    SetRevision() {
      this.$http.url = selectConfig.com.revision.getversion;
      this.$http.param = {revisionNum: this.data.revisionNum}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data.revisionNum = _result.data;
        this.data.revisionContent = '';
        this.data.regDtStr = '';
        this.data.regUserName = '';
        this.data.sopMocId = '';
        this.isRevision = true;
      });
    },
    CancelRevision() {
      this.isRevision = false;
      this.getData();
    },
    getRevList(_groupId) {
      this.$http.url = this.revlistUrl;
      this.$http.type = 'GET';
      this.$http.param = {groupId: _groupId};
      this.$http.request((_result) => {
        this.gridrev.data = _result.data;
      },);
    },
    rowClickRev(row) {
      if (row) {
        this.selectedpsiFireproofStructureId = row;
        if (this.selectedpsiFireproofStructureId != this.data.psiFireproofStructureId) {
          this.getDetail(this.selectedpsiFireproofStructureId, false);
        }
      }
    },
  }
};
</script>
